/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* POPPINS FAMILY */

*{
  font-family: Poppins-Regular;
  src: url(./fonts/Popins/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins-Regular;
  src: url(./fonts/Popins/Poppins-Regular.ttf);
}


@font-face {
  font-family: Poppins-Medium;
  src: url(./fonts/Popins/Poppins-Medium.ttf);
}

@font-face {
  font-family: Comfortaa-Regular;
  src: url(./fonts/Comfortaa/Comfortaa-Regular.ttf);
}

#inputCategories:focus {
  outline: none;
}

#scrollableDiv::-webkit-scrollbar {
  height: 5px;
}

.textPrimaryLanding {
  margin-top: 2.58rem;
  margin-bottom: 2.5rem;
  font-family: Poppins-Medium;
  font-size: 1.5rem;
  color: #020066;
}

.textPrimaryLabelInput {
  font-family: Poppins-Regular;
  font-weight: 400;
  color: #000000;
}

.textPrimaryInput {
  font-family: Poppins-Medium;
  font-weight: 500;
  padding-left: 0.93rem;
  color: #A0A0A0;
}

.textPrimaryButton {
  font-family: Poppins-Medium;
  font-weight: 400;
  color: white;
  font-size: 1.12rem;
}

.textErrorInput {
  color: red;
  font-family: Poppins-Regular;
  position: absolute;
  top: 97px;
  font-size: 0.975rem;
}

/* SIDEBAR */


.textCheckedSidebar {
  color: white;
  font-family: Poppins-Medium;
  font-size: 1rem;
  position: relative;
  top: 8px;
  margin-left: 12px;
  white-space: nowrap;
  margin-bottom: 10px;
}

.positionInputCategory {
  color: white;
  font-family: Poppins-Medium;
  font-size: 1rem;
  position: relative;
  right: 10px;
  top: 8px;
}

.textAddNewServices {
  font-family: Poppins-Medium;
  color: #020066;
  position: relative;
  top: 7px;
}

.textPrimaryInputCategory {
  color: white;
  font-family: Poppins-Medium;
  font-size: 0.95rem;
  position: relative;
  top: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; 
}

.textNotCheckedSidebar {
  color: #787486;
  font-family: Poppins-Medium;
  font-size: 1rem;
  position: relative;
  top: 8px;
  white-space: nowrap;
  margin-left: 12px;
  margin-bottom: 10px;
}

.textClientInfoHeader {
  margin-top: 15px;
  font-size: 0.9rem;
  font-family: Comfortaa-Regular;
  position: relative;
  right: 12px;
}

.textPrimaryHeaderDashboard {
  font-family: Poppins-Medium;
  font-size: 1.87rem;
  color: #000;
}

.textNotChecked {
  font-family: Poppins-Medium;
  font-weight: 500;
  font-size: 0.93rem;
  color: #020066;
}


.textChecked {
  font-family: Poppins-Medium;
  font-weight: 500;
  font-size: 0.93rem;
  color: white;
}

.inputSelectFilterManager {
  font-family: Poppins-Regular;
  font-weight: 400;
  color: #7D7D7D;
}


.textHeaderTableList {
  font-family: Poppins-Medium;
  color: #6A6A6A;
  font-size: 0.87rem;
  text-align: center;
  position: relative;
}


.textTableList {
  font-family: Poppins-Medium;
  color: black;
  font-size: 0.87rem;
  width: 190px;

  text-align: center;
  position: relative;
}

.textBtnProfile {
  font-family: Poppins-Medium;
  color: white;
  font-size: 0.81rem;
}

.textHeaderCardRequest {
  font-family: Poppins-Medium;
  color: #020066;
  height: 0px;
  font-size: 1.75rem;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  font-family: Poppins-Medium;
  color: #6A6A6A;

}


td {
  border-top: solid 1px #D7D7D7;
  border-left: solid 1px #D7D7D7;
  border-right: solid 1px #D7D7D7;

  border-bottom: solid 1px #D7D7D7;
  border-style: none solid solid none;
  padding: 18px;
  background: white;
}

tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

tr:first-child td {
  border-top-style: solid;
}

tr td:first-child {
  border-left-style: solid;
}

.textInfoCardRequest {
  font-family: Poppins-Medium;
  font-weight: 400;
  font-size: 0.81rem;
  margin-top: 11px;

}

.textSecondaryCardPortifolio {
  font-family: Poppins-Medium;
  font-size: 0.75rem;
  margin-top: 12px;
  color: #000000;
}


::-webkit-scrollbar {
  width: 9px;
  height: 10px;
  background: #D8D8D8;
  border-radius: 50px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #D8D8D8;
  border-radius: 10px;
  height: 35px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.33);
  border-radius: 10px;
}


/* MODAL */

.modal-filter-table-list-user {
  width: 74.414vw;
  max-width: 50vw;
}

.modal-filter-option {
  width: 26vw;
  max-width: 30vw;
}

.modal-filter-more {
  width: 70vw;
  max-width: 50vw;
}

.modal-filter-edit {
  width: 26vw;
  max-width: 30vw;
}


.textTypeInfoModal {

  font-family: Poppins-Medium;
  color: black;
  font-size: 1.125rem;
  width: 265px;
}


.textHeaderModalUser {
  font-family: Poppins-Medium;
  font-size: 1.12rem;
  margin-top: 1rem;
}

.textBtnFilterManagerModal {
  font-family: Poppins-Medium;
  color: white;
  font-size: 0.87rem;
}

.textBtnUsers {
  font-family: Poppins-Medium;
  font-size: 0.81rem;
  color: white;
}

.textUserNameModal {
  font-family: Poppins-Medium;
  color: black;
  font-size: 1.31rem;
  margin-top: 0.62rem;
}

.numberStars {
  font-family: Poppins-Medium;
  color: black;
  font-size: 0.87rem;
}

.numberAvaliations {

  font-family: Poppins-Medium;
  color: black;
  font-size: 0.87rem;

}

/* CARD AVALIATION */

.textPrimaryCardAvaliation {
  font-family: Poppins-Medium;
  color: #0E0E0E;
  font-size: 0.87rem;
  position: relative;
  top: 5px;
}

.textSecondaryCardAvaliation {
  font-family: Poppins-Regular;
  color: #000000;
  margin-top: 1rem;
}

.textPrimaryCardVerification {
  font-family: Poppins-Regular;
  color: black;
  font-size: 0.87rem;
  position: relative;
  top: 11px;
  left: 10px;
}

/* BTN CARD VERIFICATION */

.textBtnDownloadCardVerification {
  font-family: Poppins-Medium;
  color: #020066;
  font-size: 0.81rem;
}

.textBtnCancelCardVerification {
  font-family: Poppins-Medium;
  color: #EA0000;
  font-size: 0.81rem;
}

.textBtnApprovedCardVerification {
  font-family: Poppins-Medium;
  color: #062F67;
  font-size: 0.81rem;
}



/* CONTENT CARD STYLE VERIFICATION */

.textPrimaryContentCardVerification {
  font-family: Poppins-Medium;
  color: #808080;
  font-size: 0.87rem;
}

.textSecondaryContentCardVerification {
  font-family: Poppins-Medium;
  color: black;
  font-size: 0.87rem;
}



/* SIDEBAR CONFIG AREA */

.textServicesSidebarConfig {
  font-family: Poppins-Medium;
  color: #020066;
  position: relative;
  top: 7px;
  left: 26px;
  font-size: 0.93rem;
}

.textTitlePowerBI {
  color: #000;
  font-family: Poppins-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical; 
}